import React, { useEffect, useState } from "react";

import * as mainStyles from "../styles/index.module.scss";
import clsx from "clsx";
import axios from "axios";
import Spinner from "./spinner";

const AutoCompound = ({ chains, images, testnet }) => {
  const [ restakeChains, setRestakeChains ] = useState([]);

  useEffect(() => {
    axios
      .get("https://validators.cosmos.directory/Brochain/chains")
      .then(response => response.data)
      .then(data => setRestakeChains(data.chains))
      .catch(() => {});
  }, []);

  const getExponent = denom => {
    let exponent = 6;
    let exp = denom[0];
    switch (exp) {
      case "a":
        exponent = 18;
        break;
      case "u":
      default:
    }
    return exponent;
  };

  const reduceValue = (value, denom) => value * Math.pow(10, -getExponent(denom) + 19) / 10e18;

  const filteredChains = chains.filter(
    edge =>
      !edge.node.hidden && !edge.node.disabled && restakeChains.find(c => edge.node.key.replace("-", "") === c.name)
  );

  return (
    <React.Fragment>
      <div className={clsx(mainStyles.gridRow)}>
        {filteredChains.map((edge, i) => {
          const chain = edge.node;

          const src = images.find(edge => edge.node.relativePath === `chain-icons/${chain.logo}`).node.publicURL;
          const metrics = restakeChains.find(c => chain.key.replace("-", "") === c.name);

          return (
            <div key={i} className={clsx(mainStyles.gridColXxs12, mainStyles.gridColM6)}>
              <a className={mainStyles.chainCard} href={chain.stakeUrl} target="_blank" rel="noreferrer">
                <div className={mainStyles.flexRow}>
                  <img src={src} alt={chain.name} className={mainStyles.chainLogo} />
                  <div>
                    <h3>{chain.name}</h3>
                    {metrics ? (
                      <React.Fragment>
                        <dl>
                          <dt>Schedule:</dt>
                          <dd>
                            <strong>{metrics.restake.run_time}</strong>
                          </dd>
                        </dl>
                        <dl>
                          <dt>Min. restake:</dt>
                          <dd>
                            <strong>
                              {reduceValue(metrics.restake.minimum_reward, chain.services.denom)}{" "}
                              {chain.services.denom.substr(1).toUpperCase()}
                            </strong>
                          </dd>
                        </dl>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
      {!filteredChains.length ? (
        <h3>
          <Spinner /> Loading...
        </h3>
      ) : null}
    </React.Fragment>
  );
};

export default AutoCompound;
