import React from "react";
import * as styles from "./spinner.module.scss";

const Spinner = props => (
  <div className={styles.ldsRipple} {...props}>
    <div />
    <div />
  </div>
);

export default Spinner;
