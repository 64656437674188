import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import AutoCompound from "../components/auto-compound"

export const Head = ({ location }) => (
  <Seo title="Auto compounding" pathname={location.pathname} />
)

const Home = ({ data }) => {
  return (
    <Layout>
      <h1>Auto compounding</h1>
      <p>
        Brochain will perform auto-compounding of your staked assets using{" "}
        <a href="https://restake.app" target="_blank" rel="noreferrer">
          Restake.app
        </a>
        . The service is free for all delegators and fees will be covered by us.
      </p>
      <p>Click on any of the cards below to start using this service.</p>
      <AutoCompound
        chains={data.allChainsJson.edges}
        images={data.allFile.edges}
      />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    allChainsJson {
      edges {
        node {
          id
          about
          explorerUrl
          stakeUrl
          hidden
          disabled
          key
          logo
          name
          site
          services {
            tmVersion
            gitRepo
            binary
            root
            chainId
            denom
            snapshot
            installation {
              genesisUrl
              addrbookUrl
              seeds
              installScript
            }
            stateSync {
              rpc
              peer
            }
          }
        }
      }
    }
    allTestChainsJson {
      edges {
        node {
          id
          about
          explorerUrl
          hidden
          disabled
          key
          logo
          name
          site
          services {
            tmVersion
            gitRepo
            binary
            root
            chainId
            denom
            snapshot
            installation {
              genesisUrl
              addrbookUrl
              seeds
              installScript
            }
            stateSync {
              rpc
              peer
            }
          }
        }
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
